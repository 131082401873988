import React from 'react';
import '../styles/Signin.css'

const Signin = ({children, flImage}) => {
  return (
    <>
      <header style={{backgroundColor: 'white'}} className="bg-white-new pt-4 pb-4">
        <nav className="">
          <div className="container nav-bar-cont">
            <a className="navbar-brand" href="#">
              {/* <img src={require('../assets/hela@10x-100.png')} alt="globalworkers" /> */}
            </a>
          </div>
        </nav>
      </header>
      <div className="main-container">
      <div className="left-container">
        {children}
      </div>
        <div className="right-container d-none d-md-block">
          <img src={flImage} alt="noimt" />
        </div>
      </div>
    </>
  );
};

export default Signin;

