import React, { useEffect, useContext } from "react";
import { useState } from "react";
import adminContext from '../context/AdminContext';
import Spinner from "./Spinner";
const CardInfo = () => {

  const context = useContext(adminContext);
  const {saveAdminBankInfo} = context;
  const [ibanData, setIbanData] = useState({
    iban: "",
    swiftCode: "",
    ibanBankName: "",
    city: "",
    country: "",
  });
  const [bankTransferData, setBankTransferData] = useState({
    accountHolder: "",
    bankAccountNumber: "",
    clearingNumber: "",
    bankName: "",
  });

  const [loading, setLoading] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)

  useEffect(()=>{
    const getBankData = async () =>{
      try {
        const response = await fetch("https://globalworker-backend.vercel.app/api/admin/bankinfo/getadminbankinfo", {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem('token'),
          },
        })

        if(!response.ok){
          console.log("Error while fetching the admin Bank info");
        }
        const data = await response.json();
        console.log(data)
        if(data.ibanInformation){
          setIbanData(data.ibanInformation);
        }
        if(data.bankTransferInfo){
          setBankTransferData(data.bankTransferInfo);
        }
        if(data.bankTransferInfo && data.ibanInformation){
          setIbanData(data.ibanInformation)
          setBankTransferData(data.bankTransferInfo)
        }
      } catch (error) {
        console.log("Error. Please try again later");
      }
    }
    getBankData();
  }, [])

  const handleIbanOnChange = (e) => {
    setIbanData((prev) => {
      let helper = { ...prev };
      helper[`${e.target.id}`] = e.target.value;
      return helper; 
    });
  };
  const handleBankTransfer = (e) => {
    setBankTransferData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSave = async (e) =>{
    e.preventDefault();
    setLoading(true)
    setBtnDisabled(true)
    
    try {
      const response = await saveAdminBankInfo({ibanInformation: ibanData, bankTransferInfo: bankTransferData});
   
      if(response){
        console.log("Data Saved successfully");
        appendAlert("Data Saved Successfully.", "success");
        setLoading(false)
        setBtnDisabled(false)
      }
      else{
        console.log("Error while saving data");  
        appendAlert("Please try again later.", "danger");
        setLoading(false)
        setBtnDisabled(false)
      }
    } catch (error) {
      console.log("Error while saving data");
      appendAlert("Please try again later.", "danger");
      setLoading(false)
      setBtnDisabled(false)
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
    const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }
  return (
    <form onSubmit={handleSave} className="companyInfo-form">
    <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
      <div className="row-1 company-info-row1">
        <div className="col">
          <h6 className="form-heading">IBAN-BETALNING</h6>
          <div className="form-div row g-3">
            <div className="">
              <label htmlFor="iban" className="label form-label">
                IBAN
              </label>
              <input onChange={handleIbanOnChange}
                value={ibanData.iban}
                type="text"
                className="form-input form-control"
                id="iban"
              />
            </div>

            <div className="">
              <label htmlFor="swiftCode" className="label form-label">
                SWIFT/BIC
              </label>
              <input onChange={handleIbanOnChange}
                value={ibanData.swiftCode}
                type="text"
                className="form-input form-control"
                id="swiftCode"
              />
            </div>
            <div className="">
              <label htmlFor="ibanBankName" className="label form-label">
                Bank
              </label>
              <input onChange={handleIbanOnChange}
                value={ibanData.ibanBankName}
                type="text"
                className="form-input form-control"
                id="ibanBankName"
              />
            </div>
            <div className="">
              <label htmlFor="city" className="label form-label">
                Stad
              </label>
              <input onChange={handleIbanOnChange}
                value={ibanData.city}
                type="text"
                className="form-input form-control"
                id="city"
              />
            </div>
            <div className="">
              <label htmlFor="country" className="label form-label">
                Land
              </label>
              <input onChange={handleIbanOnChange}
                value={ibanData.country}
                type="text"
                className="form-input form-control"
                id="country"
              />
            </div>
          </div>
        </div>
        <div className="col">
          <h6 className="form-heading">BANKGIRERING</h6>
          <div className="form-div row g-3">
            <div className="">
              <label htmlFor="accountHolder" className="label form-label">
                Ägare av bankkonto
              </label>
              <input 
                onChange={handleBankTransfer}
                value={bankTransferData.accountHolder}
                type="text"
                className="form-input form-control"
                id="accountHolder"
              />
            </div>

            <div className="">
              <label htmlFor="bankAccountNumber" className="label form-label">
                Bankkontonummer
              </label>
              <input onChange={handleBankTransfer}
                value={bankTransferData.bankAccountNumber}
                type="text"
                className="form-input form-control"
                id="bankAccountNumber"
              />
            </div>
            <div className="">
              <label htmlFor="clearingNumber" className="label form-label">
                Clearingnummer
              </label>
              <input onChange={handleBankTransfer}
                value={bankTransferData.clearingNumber}
                type="text"
                className="form-input form-control"
                id="clearingNumber"
              />
            </div>
            <div className="">
              <label htmlFor="bankName" className="label form-label">
                Bank
              </label>
              <input onChange={handleBankTransfer}
                value={bankTransferData.bankName}
                type="text"
                className="form-input form-control"
                id="bankName"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="save-companyInfo-div mb-3">
        <button disabled={btnDisabled} type="submit">Spara</button>
      </div>
      <div
          style={{ paddingLeft: "148px", width: '93%' }}
          className="sendBtn-div mb-3"
        >
          {loading && <Spinner />}
        </div>
    </form>
  );
};

export default CardInfo;
